.section-project-logos {
  .homepage-logo {
    width: 100%;
    max-width: 200px;
    filter: grayscale(1);
    transition: all 0.3s ease;
    padding: 25px 5px;
    opacity: 0.6;

    &:hover {
      filter: grayscale(0);
      opacity: 1;
    }
  }
}
